import React, {useState} from 'react'
import axios from 'axios';
import {API_URL} from '../Constants'

const VerifyPassScreen = ({location,match}) => {
    const token = location.search ? String(location.search.split('=')[1]) : ''
    const userId = match.params.id ? String(match.params.id) : ''
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState(null)

    const submitHandler = async(e) =>{
        e.preventDefault()
        if(password !== confirmPassword){
            setMessage('passwords do not match')
        }else{
            const config = {
                headers:{
                    'Content-Type': 'application/json'
                }
            }
            const {data} = await axios.post(API_URL+'/api/users/verifyResetPassword/'+userId+"/"+token,{password},config)

            setMessage(data.msg)

        }

    }
  return (
    <div className='login-view'>
    <section className="bg-img">
        <h2>ETHΞREANS</h2>
       
        <div className='login-form'>
            <form name="contact" onSubmit={submitHandler} >

            <p>
                <label> <input placeholder="New password" type="password" className="nice-inputs" value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)}  required/></label>
            </p>
            <p>
                <label> <input placeholder="Confirm Password" type="password" className="nice-inputs" value={password} onChange={(e)=> setPassword(e.target.value)}  required/></label>
            </p>
        
            <p>
                <button className="login-btn" type="submit">Reset</button>
            </p>
            </form>
            {message ?? <p>{message}</p>}

        </div>
        
    </section>

</div>
  )
}

export default VerifyPassScreen