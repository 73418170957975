import {BrowserRouter as Router, Route} from 'react-router-dom';
import LoginScreen from './screens/LoginScreen';
import  MainScreen  from './screens/MainScreen';
import {Container} from 'react-bootstrap'
import RegisterScreen from './screens/RegisterScreen';
import TestViewScreen from './screens/TestViewScreen';
import ResetPassScreen from './screens/ResetPassScreen';
import VerifyPassScreen from './screens/VerifyPassScreen';
const App = () =>{
  return (
    
      <Router>     
        
      <main>

        <Container>
          <Route path="/login" component={LoginScreen}  />
          <Route path="/" component={MainScreen} exact/>
          <Route path="/resetpass" component={ResetPassScreen}  />
          <Route path="/newPassword/:id"  component={VerifyPassScreen} />
          <Route path="/register" component={RegisterScreen} />
        </Container>
      </main>
    </Router>

  )
}




export default App;
