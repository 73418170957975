import {createStore, combineReducers, applyMiddleware,compose } from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import { loginReducer,registerReducer} from './reducers/userReducer'

const reducer = combineReducers({
    userLogin:loginReducer,
    userRegister:registerReducer
})

const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: {userInfo:userInfoFromStorage}
}

const middleware = [thunk];
//const store = createStore(reducer,initialState,composeWithDevTools(applyMiddleware(...middleware)));
const store = createStore(reducer,initialState,compose(applyMiddleware(...middleware)));

export default store;

