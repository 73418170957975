import {Link} from 'react-router-dom';
import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios';
import {API_URL} from '../Constants'

const ResetPassScreen = () => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState(null)

    const submitHandler = async(e) =>{
        e.preventDefault()
        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        }
        const {data} = await axios.post(API_URL+'/api/users/resetPassword',{email},config)
        setMessage(data.msg);
    }
  return (
    <div className='login-view'>
    <section className="bg-img">
        <h2>ETHΞREANS</h2>
       
        <div className='login-form'>
            <form name="contact" onSubmit={submitHandler} >
            <p>
                <label> <input placeholder="Email" type="text" className="nice-inputs" value={email} onChange={(e)=> setEmail(e.target.value)}  required/></label>
            </p>        
            <p>
                <button className="login-btn" type="submit">Reset</button>
            </p>
            </form>
        </div>
        <p><Link to={'/login'}>Log in here</Link></p>
        {message ?? <p>{message}</p>}
    </section>

</div>
  )
}

export default ResetPassScreen