import MetaMaskOnboarding from '@metamask/onboarding'
import { Container, Row,Col,Button } from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JsFileDownloader from 'js-file-downloader';
import {newAddress} from '../actions/userActions';
import Header from '../components/Header'
import nave from '../imgs/fighter_red_Front_V1.png'
import {API_URL} from '../Constants'

 const MainScreen = ({location,history}) => {
     const isInstalled = MetaMaskOnboarding.isMetaMaskInstalled();
     const dispatch = useDispatch()

    const [address,setAddress] = useState('');
    const [id, setId] = useState('');
    const { ethereum } = window;
     let isConnected;
     const installOnBoarding = ()=>{
        const meta = new MetaMaskOnboarding()
        meta.startOnboarding();
    }



       const connectMetaMask = async () => {
        try {
          // Will open the MetaMask UI
          // You should disable this button while the request is pending!
          let address1  = await ethereum.request({ method: 'eth_requestAccounts' });
          setAddress(address1);
          /*isConnecting = true;
            connection();
          if(connection()){
            isConnecting = false;
          }*/
         dispatch(newAddress(address1[0],userInfo.id))
          //send request to api to save new address

          



        } catch (error) {
          console.error(error);

        }
      };

      const deleteAddress = async() =>{
        setAddress("");
        /*isConnecting = true;
          connection();
        if(connection()){
          isConnecting = false;
        }*/
       dispatch(newAddress("",userInfo.id))
        //send request to api to save new address
      }

      const downloadFile =  ()=>{
        const fileUrl = API_URL+'/download-file?fileKey=ethereans-client-1.0.31.exe.zip';

        new JsFileDownloader({ 
            url: fileUrl
          })
          .then(function () {
            // Called when download ended
           
          })
          .catch(function (error) {
            // Called when an error occurred
          });


      }


       const userLogin = useSelector(state => state.userLogin)
       const {userInfo} = userLogin
      
       

       useEffect(()=>{
        if(!userInfo){
            history.push('/login')
        }else{
         
          if(userInfo.addressWallet){
            setAddress(userInfo.addressWallet)
            
          }
            
            
        }
    },[dispatch,history, userInfo])
    return (
        <Container>
          <Header />
            <Row className='justify-content-md-center'>
                <Col xs={12} md={6}>
                   { address ? <><p>Your current wallet address {address}</p> 
                   <Button onClick={deleteAddress} variant="danger">Delete address</Button>

                   <br/>
                   <a  href={'https://ethereansforgefiles.s3.amazonaws.com/ethereans-client-1.0.51.zip'}>Download Ethereans client</a>

                   </>
                   :
                   
                   !isInstalled ?
                    <><h3>Please install metamask to continue</h3>
                    <Button onClick={installOnBoarding}>Install Metamask</Button>  </> :
                    <>
                    {!address ?<> <h3>Connect your metamask wallet to continue</h3>
                        <Button onClick={connectMetaMask}  
>Connect Metamask</Button></> :  <Button onClick={downloadFile}>Download Ethereans Forge client</Button>
}   

                    </>
                    
                    
                   }
                   <br/>
                   <hr/>

                </Col>
            </Row>
        </Container>


    /*<div>
      <div className="nav"> 
      ETHΞREANS FORGE
      <img className="img-nave" src={nave} />
      {!address ?<> <h3>Connect your metamask wallet to continue</h3>
                        <Button onClick={connectMetaMask}  
>Connect Metamask</Button></> :  <Button onClick={downloadFile}>Download Ethereans Forge client</Button>}
      </div>
    </div>*/
    )
}

export default MainScreen