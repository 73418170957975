import axios from 'axios';
import {API_URL} from '../Constants'

export const login = (email, password) => async (dispatch)=>{
    try {
        dispatch({
            type:'USER_LOGIN_REQUEST'
        })
        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        }
        const {data} = await axios.post(API_URL+'/api/users/login',{email,password},config)

        
        if(data.success){
                
                const token = data.token.split(' ')[1];
                const user = {
                    token:token,
                    email:data.user.email,
                    id:data.user._id,
                    addressWallet:data.user.addressWallet
                }
                dispatch({
                    type:'USER_LOGIN_SUCCESS',
                    payload:user
                })
                localStorage.setItem('userInfo',JSON.stringify(user));
            

        }else{
            dispatch({
                type:'USER_LOGIN_FAIL',
                payload: data.msg
            })
        }

        

    } catch (error) {
         dispatch({
            type:'USER_LOGIN_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const register = (email, password) => async (dispatch)=>{
    try {
        dispatch({
            type:'USER_REGISTER_REQUEST'
        })
        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        }
        const {data} = await axios.post(API_URL+'/api/users/',{email:email,password:password},config)
        if(data.success){
            /*const token = data.token.split(' ')[1];
            console.log(token)
            const user = {
                token:token,
                email:data.user.email,
                id:data.user._id,
                addressWallet:data.user.addressWallet
            }*/
            let msg = {
                msg:'User registered, now you can login'
            }
            dispatch({
                type:'USER_REGISTER_SUCCESS',
                payload:msg
            })
            //localStorage.setItem('userInfo',JSON.stringify(user));
        }else{
            dispatch({
                type:'USER_REGISTER_FAIL',
                payload: data.msg
            })
        }


    } catch (error) {
         dispatch({
            type:'USER_REGISTER_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}



export const newAddress = (address,id) => async (dispatch)=>{
    try {
        dispatch({
            type:'USER_NEWADDRESS_REQUEST'
        })
        const config = {
            headers:{
                'Content-Type': 'application/json'
            }
        }
        
        const {data} = await axios.post(API_URL+'/api/users/newAddress',{address:address,id:id},config)
       
        const token = data.token.split(' ')[1];
        const user = {
            token:token,
            email:data.user.email,
            id:data.user._id,
            addressWallet:data.user.addressWallet
        }
        dispatch({
            type:'USER_NEWADDRESS_SUCCESS',
            payload:user
        })
        localStorage.setItem('userInfo',JSON.stringify(user));
    } catch (error) {
        dispatch({
            type:'USER_NEWADDRESS_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
    }
}

export const logout = ()=> async (dispatch) =>{
    localStorage.removeItem('userInfo');
    sessionStorage.removeItem('userInfo');

    dispatch({type:'USER_LOGOUT'});
}