import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';
import {logout} from '../actions/userActions';

const Header = () => {

    const dispatch = useDispatch();
    const userLogin = useSelector(state=> state.userLogin)
    
   
    const logoutHandler = ()=>{
        dispatch(logout())
    }
   


    return (
        <header>
<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
  <Container>
  <Navbar.Brand href="#home">THE ETHΞREANS</Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">

    </Nav>
    <Nav>
      <Nav.Link  onClick={logoutHandler}>Logout</Nav.Link>

    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
        </header>
    )
}

export default Header
