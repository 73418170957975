import React, {useState, useEffect} from 'react'
import{Form,Button,Row,Col} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer';
import {Link} from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import {register} from '../actions/userActions';
const RegisterScreen = ({location,history}) => {
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [message, setMessage] = useState(null)



    const dispatch = useDispatch()
    const userRegister = useSelector(state => state.userLogin)
    const {loading, error,userInfo} = userRegister
    //const redirect = location.search ? location.search.split('=')[1] : '/'
    useEffect(()=>{
        if(userInfo){
            history.push('/')
        }
    },[history, userInfo])
    const submitHandler = (e) =>{
        e.preventDefault()
        if(password !== confirmPassword){
            setMessage('passwords do not match')
        }else{
            
            dispatch(register(email,password))

            if(!error){
                setMessage('User registered, now you can login');
               
            }
            //history.push('/')
            //
        }
       //dispatch register
    }
    return (
        /*<FormContainer>
            <h1>Sign Up</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=> setEmail(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='confirmPassword'>
                    <Form.Label>confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="confirmPassword" value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)}></Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e)=> setPassword(e.target.value)}></Form.Control>
                </Form.Group>

                <Button type='submit' variant='primary'>
                    Register
                </Button>
            </Form>
            <Row className='py-3'>
                <Col>
                   Have an Account? <Link to={'/login'}>Login</Link>
                </Col>
            </Row>
        </FormContainer>*/
        <div className='login-view'>
        <section className="bg-img">
            <h2>ETHΞREANS</h2>
           
            <div className='login-form'>
                <form name="contact" onSubmit={submitHandler} >
                <p>
                    <label> <input placeholder="Email" type="text" className="nice-inputs" value={email} onChange={(e)=> setEmail(e.target.value)} required/></label>
                </p>


                <p>
                    <label> <input placeholder="Confirm password" type="password" className="nice-inputs" value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)}  required/></label>
                </p>
                <p>
                    <label> <input placeholder="Password" type="password" className="nice-inputs" value={password} onChange={(e)=> setPassword(e.target.value)}  required/></label>
                </p>
            
                <p>
                    <button className="login-btn" type="submit">Sign up</button>
                </p>
                </form>
            </div>
            <br></br>
            {error ?? <p>{error}</p>}
            <br/>
            {message ?? <p>{message}</p>}
            <p>Already have an account?  <Link to={'/login'}>Log in here</Link></p>
            
        </section>

    </div>
    )
}

export default RegisterScreen
