import FormContainer from '../components/FormContainer';
import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom';
import {login} from '../actions/userActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
 const LoginScreen = ({location,history}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const {loading, error,userInfo} = userLogin

    useEffect(()=>{
        if(userInfo){
            history.push('/')
        }
    },[history, userInfo])
    const submitHandler = (e) =>{
        e.preventDefault()
       dispatch(login(email,password))
       
    }
    return (
        /*<FormContainer>
            <h1>Ethereans Forge</h1>
            <h3>Sign In</h3>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=> setEmail(e.target.value)}></Form.Control>
                </Form.Group>
                <Form.Group controlId='password'>
                    <Form.Label>password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" value={password} onChange={(e)=> setPassword(e.target.value)}></Form.Control>
                </Form.Group>
                <Button type='submit' variant='primary'>
                    Sign in
                </Button>
            </Form>
            <Row className='py-3'>
                <Col>
                    New User? <Link to={'/register'}>Register</Link>
                </Col>
            </Row>
        </FormContainer>*/
        <div className='login-view'>
            <section className="bg-img">
                <h2>ETHΞREANS</h2>
               
                <div className='login-form'>
                    <form name="contact" onSubmit={submitHandler} >
                    <p>
                        <label> <input placeholder="Email" type="text" value={email} onChange={(e)=> setEmail(e.target.value)} className="nice-inputs" required/></label>
                    </p>

                    <p>
                        <label> <input placeholder="Password" type="password" value={password} onChange={(e)=> setPassword(e.target.value)} className="nice-inputs"  required/></label>
                    </p>
                
                    
                        {
                            loading ? <Loader/> : <button className="login-btn" type="submit">Log in</button>
                        }
                        
                    
                    </form>
                </div>
                <br/>
                {error ?? <p>{error}</p>}
                <br/>
                <p>New user?  <Link to={'/register'}>Register here</Link></p>
                <p>Forgot your password? <Link to={'/resetpass'}>Click here here</Link></p>
            </section>

        </div>

    )

}
export default LoginScreen